export const appPaths = {
    adminAccess: 'adminaccess',
    clientAccess: 'clientaccess',
    clientDocumentUpload: 'upload-documents',
    forgotPassword: 'forgotpassword',
    home: 'home',
    login: 'login',
    myProfile: 'my-profile',
    requestAccess: 'requestaccess',
    resetPassword: 'resetpassword',
    serviceRequest: 'service-request',
    settings: 'settings',
    verify2FA: 'verify',
};
