import { IDynamicConfig, IDynamicFormConfig, DynamicConfig } from '@mt-ng2/dynamic-form';

import { TaskDynamicControls, ITaskDynamicControlsParameters } from '@model/form-controls/task.form-controls';
import { ITask } from '@model/interfaces/task';
import { ITaskStatus } from '@model/interfaces/task-status';

export class TaskDynamicConfig<T extends ITask> extends DynamicConfig<T> implements IDynamicConfig<T> {
    constructor(private task: T, private taskStatuses?: ITaskStatus[], private configControls?: string[]) {
        super();
        const additionalParams: ITaskDynamicControlsParameters = {
            // assignedTos: this.assignedTos,
            taskStatuses: this.taskStatuses,
        };
        const dynamicControls = new TaskDynamicControls(this.task, additionalParams);
        // default form implementation can be overridden at the component level
        if (!configControls) {
            this.configControls = ['TaskStatusId', 'DateCreated', 'CreatedById', 'DateCreated', 'Archived', 'IsAutomated'];
        }
        this.setControls(this.configControls, dynamicControls);
    }

    getForUpdate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
            viewOnly: this.DynamicLabels,
        };
    }

    getForCreate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
        };
    }
}
