import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

export const enum ModalTypes {
    DOCUMENTS,
    FILM,
}

@Injectable({
    providedIn: 'root',
})
export class ModalService {
    modalTypeClassDict: { [type: number]: string } = {
        [ModalTypes.DOCUMENTS]: 'document-card-modal',
        [ModalTypes.FILM]: 'film-modal',
    };

    constructor(private ngbModal: NgbModal) {}

    showDocumentModal(modal, modalType): void {
        if (this.ngbModal.hasOpenModals()) {
            this.ngbModal.dismissAll();
        }

        this.ngbModal.open(modal, { windowClass: this.modalTypeClassDict[modalType] });
    }
}
