import { IMetaItem } from "@mt-ng2/base-service";
import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';

import { IExpandableObject } from '../expandable-object';
import { IAddress } from '../interfaces/address';
import { IUser } from '../interfaces/user';
import { IState } from '../interfaces/state';

export interface IAddressDynamicControlsParameters {
    formGroup?: string;
    states?: IState[];
    createdBies?: IUser[];
    modifiedBies?: IUser[];
}

export class AddressDynamicControls {

    formGroup: string;
    states: IState[];
    createdBies: IUser[];
    modifiedBies: IUser[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private address?: IAddress, additionalParameters?: IAddressDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'Address';
        this.states = additionalParameters && additionalParameters.states || undefined;
        this.createdBies = additionalParameters && additionalParameters.createdBies || undefined;
        this.modifiedBies = additionalParameters && additionalParameters.modifiedBies || undefined;

        this.Form = {
            Address1: new DynamicField({
                formGroup: this.formGroup,
                label: 'Address1',
                name: 'Address1',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required, Validators.maxLength(300) ],
                validators: { 'required': true, 'maxlength': 300 },
                value: this.address && this.address.hasOwnProperty('Address1') && this.address.Address1 !== null ? this.address.Address1.toString() : '',
            }),
            Address2: new DynamicField({
                formGroup: this.formGroup,
                label: 'Address2',
                name: 'Address2',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(300) ],
                validators: { 'maxlength': 300 },
                value: this.address && this.address.hasOwnProperty('Address2') && this.address.Address2 !== null ? this.address.Address2.toString() : '',
            }),
            Archived: new DynamicField({
                formGroup: this.formGroup,
                label: 'Archived',
                name: 'Archived',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.address && this.address.hasOwnProperty('Archived') && this.address.Archived !== null ? this.address.Archived : false,
            }),
            City: new DynamicField({
                formGroup: this.formGroup,
                label: 'City',
                name: 'City',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required, Validators.maxLength(200) ],
                validators: { 'required': true, 'maxlength': 200 },
                value: this.address && this.address.hasOwnProperty('City') && this.address.City !== null ? this.address.City.toString() : '',
            }),
            CreatedById: new DynamicField({
                formGroup: this.formGroup,
                label: 'Created By',
                name: 'CreatedById',
                options: this.createdBies,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.address && this.address.CreatedById || null,
            }),
            DateCreated: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date Created',
                name: 'DateCreated',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.address && this.address.DateCreated || null,
            }),
            DateModified: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date Modified',
                name: 'DateModified',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.address && this.address.DateModified || null,
            }),
            FacilityAddressLegacyId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Facility Address Legacy',
                name: 'FacilityAddressLegacyId',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(20) ],
                validators: { 'maxlength': 20 },
                value: this.address && this.address.hasOwnProperty('FacilityAddressLegacyId') && this.address.FacilityAddressLegacyId !== null ? this.address.FacilityAddressLegacyId.toString() : '',
            }),
            IndividualAddressLegacyId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Individual Address Legacy',
                name: 'IndividualAddressLegacyId',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(20) ],
                validators: { 'maxlength': 20 },
                value: this.address && this.address.hasOwnProperty('IndividualAddressLegacyId') && this.address.IndividualAddressLegacyId !== null ? this.address.IndividualAddressLegacyId.toString() : '',
            }),
            IsPrimary: new DynamicField({
                formGroup: this.formGroup,
                label: 'Is Primary',
                name: 'IsPrimary',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.address && this.address.hasOwnProperty('IsPrimary') && this.address.IsPrimary !== null ? this.address.IsPrimary : false,
            }),
            ModifiedById: new DynamicField({
                formGroup: this.formGroup,
                label: 'Modified By',
                name: 'ModifiedById',
                options: this.modifiedBies,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.address && this.address.ModifiedById || null,
            }),
            StateId: new DynamicField({
                formGroup: this.formGroup,
                label: 'State',
                name: 'StateId',
                options: this.states,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.address && this.address.StateId || null,
            }),
            Zip: new DynamicField({
                formGroup: this.formGroup,
                label: 'Zip',
                name: 'Zip',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required, Validators.maxLength(20) ],
                validators: { 'required': true, 'maxlength': 20 },
                value: this.address && this.address.hasOwnProperty('Zip') && this.address.Zip !== null ? this.address.Zip.toString() : '',
            }),
        };

        this.View = {
            Address1: new DynamicLabel({
			    label: 'Address1',
			    value: this.address && this.address.hasOwnProperty('Address1') && this.address.Address1 !== null ? this.address.Address1.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            Address2: new DynamicLabel({
			    label: 'Address2',
			    value: this.address && this.address.hasOwnProperty('Address2') && this.address.Address2 !== null ? this.address.Address2.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            Archived: new DynamicLabel({
			    label: 'Archived',
			    value: this.address && this.address.hasOwnProperty('Archived') && this.address.Archived !== null ? this.address.Archived : false,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Checkbox,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            City: new DynamicLabel({
			    label: 'City',
			    value: this.address && this.address.hasOwnProperty('City') && this.address.City !== null ? this.address.City.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            CreatedById: new DynamicLabel({
			    label: 'Created By',
			    value: getMetaItemValue(this.createdBies as unknown as IMetaItem[], this.address && this.address.hasOwnProperty('CreatedById') && this.address.CreatedById !== null ? this.address.CreatedById : null) ?? "",
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Select,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            DateCreated: new DynamicLabel({
			    label: 'Date Created',
			    value: this.address && this.address.DateCreated || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: InputTypes.Datepicker,
			        scale: null,
			    })
			}
            ),
            DateModified: new DynamicLabel({
			    label: 'Date Modified',
			    value: this.address && this.address.DateModified || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: InputTypes.Datepicker,
			        scale: null,
			    })
			}
            ),
            FacilityAddressLegacyId: new DynamicLabel({
			    label: 'Facility Address Legacy',
			    value: this.address && this.address.hasOwnProperty('FacilityAddressLegacyId') && this.address.FacilityAddressLegacyId !== null ? this.address.FacilityAddressLegacyId.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            IndividualAddressLegacyId: new DynamicLabel({
			    label: 'Individual Address Legacy',
			    value: this.address && this.address.hasOwnProperty('IndividualAddressLegacyId') && this.address.IndividualAddressLegacyId !== null ? this.address.IndividualAddressLegacyId.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            IsPrimary: new DynamicLabel({
			    label: 'Is Primary',
			    value: this.address && this.address.hasOwnProperty('IsPrimary') && this.address.IsPrimary !== null ? this.address.IsPrimary : false,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Checkbox,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            ModifiedById: new DynamicLabel({
			    label: 'Modified By',
			    value: getMetaItemValue(this.modifiedBies as unknown as IMetaItem[], this.address && this.address.hasOwnProperty('ModifiedById') && this.address.ModifiedById !== null ? this.address.ModifiedById : null) ?? "",
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Select,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            StateId: new DynamicLabel({
			    label: 'State',
			    value: getMetaItemValue(this.states as unknown as IMetaItem[], this.address && this.address.hasOwnProperty('StateId') && this.address.StateId !== null ? this.address.StateId : null) ?? "",
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Select,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            Zip: new DynamicLabel({
			    label: 'Zip',
			    value: this.address && this.address.hasOwnProperty('Zip') && this.address.Zip !== null ? this.address.Zip.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
        };

    }
}
