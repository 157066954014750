import { NgModule } from '@angular/core';
import { SharedModule } from '../common/shared.module';
import { ImedClaimRoutingModule } from './imed-claim-routing.module';
import { ImedClaimBasicInfoComponent } from './imed-claim-basic-info/imed-claim-basic-info.component';
import { ImedClaimDetailComponent } from './imed-claim-detail/imed-claim-detail.component';
import { ImedClaimHeaderComponent } from './imed-claim-header/imed-claim-header.component';
import { ImedClaimListComponent } from './imed-claim-list/imed-claim-list.component';
import { ImedClaimAddressBasicInfoComponent } from './address/claim-address-basic-info.component';
import { ImedClaimAddServicesComponent } from './imed-claim-add-services/imed-claim-add-services.component';
import { ImedClaimAttachmentsComponent } from './attachment/imed-claim-attachments.component';
import { ImedClaimViewServicesComponent } from './imed-claim-view-services/imed-claim-view-services.component';
import { ImedClaimServiceFollowupsComponent } from './services/follow-ups/imed-claim-service-followups.component';
import { ImedClaimServicesComponent } from './services/service-list/imed-claim-services.component';
import { TaskDetailComponent } from '../tasks/task-detail/task-detail.component';
import { TaskListComponent } from '../tasks/task-list/task-list.component';
import { TaskBasicInfoComponent } from '../tasks/task-basic-info/task-basic-info.component';
import { AddressBookSelectOptionComponent } from '../common/address-book-select-list/address-book-select-option.component';
import { TaskService } from '../tasks/task.service';
import { ImedClaimSubServiceService } from '@app-shared/services/imed-claim-sub-service.service';
import { ImedClaimRequestAccessDynamicCellComponent } from './imed-claim-list/imed-claims-request-access-cell.component';

@NgModule({
    declarations: [
        ImedClaimListComponent,
        ImedClaimAddressBasicInfoComponent,
        ImedClaimHeaderComponent,
        ImedClaimBasicInfoComponent,
        ImedClaimDetailComponent,
        ImedClaimViewServicesComponent,
        ImedClaimServicesComponent,
        ImedClaimAddServicesComponent,
        ImedClaimAttachmentsComponent,
        ImedClaimServiceFollowupsComponent,
        TaskBasicInfoComponent,
        TaskDetailComponent,
        TaskListComponent,
        AddressBookSelectOptionComponent,
        ImedClaimRequestAccessDynamicCellComponent,
    ],
    imports: [SharedModule, ImedClaimRoutingModule],
})
export class ImedClaimModule {
    static forRoot(): any {
        return {
            ngModule: ImedClaimModule,
            providers: [TaskService, ImedClaimSubServiceService],
        };
    }
}
