import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MetaItemService } from '@mt-ng2/base-service';
import { IImedClaimService } from '@model/interfaces/imed-claim-service';
import { SearchParams } from '@mt-ng2/common-classes';
import { IServiceListDTO } from '../../model/interfaces/custom/service-list-dto';

export const emptyImedClaimService: IImedClaimService = {
    Archived: false,
    CreatedById: 0,
    DateCreated: new Date(),
    HighValueService: false,
    Id: 0,
    ImedClaimId: 0,
    PhysicianId: null,
    RushService: false,
    ServiceId: 0,
    ServiceStatusId: 0,
    SpecialtyRequestedId: null,
};

@Injectable({
    providedIn: 'root',
})
export class ImedClaimServiceService extends MetaItemService<any> {
    constructor(public http: HttpClient) {
        super('ImedClaimServiceService', 'Service', 'ServiceIds', '/client/imedclaimservices', http);
    }

    getEmptyImedClaimService(): IImedClaimService {
        return { ...emptyImedClaimService };
    }

    searchImedClaimServices(imedClaimId: number, data: SearchParams): any {
        const requestParams = this.getHttpParams(data);
        const requestPayload = {};
        requestParams.keys().forEach(key => {
            requestPayload[key] = requestParams.get(key);
        });
        return this.http.post<IServiceListDTO>(`/client/imedclaimservices/${imedClaimId}/_searchServices`, requestPayload,{ observe: 'response' });
    }

    getRecordRetrievalImedClaimServiceId(imedClaimId: number): any {
        return this.http.get<IImedClaimService>(`/client/imedclaimservices/${imedClaimId}/record-retrieval`);
    }

    saveServiceWithoutTask(imedClaimService: IImedClaimService): any {
        return this.http.post<number>(`/client/imedclaimservices/create-without-task`, imedClaimService);
    }
}
