import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class LoginLogService {
    constructor(public http: HttpClient) {}

    logLogin(userId: number): any {
        return this.http.post(`/api/v1/loginLogger/log/${userId}`, {});
    }
}
