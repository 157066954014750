import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export enum AppStates {
    Main = 1,
    SearchAddressBook = 2,
    AddAddressBook = 3,
}

@Injectable({
    providedIn: 'root',
})
export class AppStateService {
    public appState: BehaviorSubject<AppStates> = new BehaviorSubject(AppStates.Main);

    constructor() {}
}
