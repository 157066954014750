export enum ServiceIdEnums {
    Record_Retrievals_HIPAA_Processing = 1,
    IME = 2,
    Film_Review = 3,
    Record_Review = 4,
    Billing_Desk_Review = 5,
    Full_Day_Testimony = 6,
    Half_Day_Testimony = 7,
    Deposition = 8,
    Conference_Call_Between_Attorney_Physician_Or_Expert = 9,
    IME_Re_Examination = 10,
    IME_Addendum_Additional_Records = 11,
    IME_Expert_Consultation = 12,
    IME_Conference_Call = 13,
    Subpoena_Processing = 29,
    Radiology_Received_From_Client = 30,
    Independent_Film_Review = 31,
}
