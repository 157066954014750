import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { StaticMetaItemService } from '@mt-ng2/base-service';
import { ICounty } from '@model/interfaces/county';

export class ZipParam {
    ZipCode: string;
}

@Injectable({
    providedIn: 'root',
})
export class CountyService extends StaticMetaItemService<any> {
    constructor(public http: HttpClient) {
        super('CountyService', 'County', 'CountyIds', '/options/counties', http);
    }

    getByZip(zip: string): Observable<ICounty> {
        const zipParam = new ZipParam();
        zipParam.ZipCode = zip;
        return this.http.post<ICounty>(`/counties/zip/`, zipParam);
    }
}
