import { IImedClaimService } from '@model/interfaces/imed-claim-service.d';
import { IImedClaimSubService } from '@model/interfaces/imed-claim-sub-service.d';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from '@mt-ng2/base-service';

export const emptyImedClaimSubService: IImedClaimSubService = {
    Archived: false,
    CreatedById: 0,
    DateCreated: new Date(),
    Id: 0,
    ImedClaimServiceId: 0,
    SubServiceId: 0,
};

@Injectable()
export class ImedClaimSubServiceService extends BaseService<IImedClaimSubService> {
    constructor(public http: HttpClient) {
        super('/imedclaimsubservices', http);
    }

    getEmptyImedClaimService(): IImedClaimSubService {
        return { ...emptyImedClaimSubService };
    }

    saveSubServices(service: IImedClaimService): Observable<any> {
        return this.http.put<any>(`/imedclaimsubservices/${service.Id}/sub-services/`, service);
    }
}
