import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EnvironmentService } from '@mt-ng2/environment-module';
import { Observable } from 'rxjs';

@Injectable()
export class LogoutLogService {
    constructor(public http: HttpClient, private environmentService: EnvironmentService) {}

    logLogout(): Observable<any> {
        return this.http.post(`/api/v1/logoutLogger/log`, {
            headers: { BypassAuth: 'true' },
        });
    }
}
