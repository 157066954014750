import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { StaticMetaItemService } from '@mt-ng2/base-service';
import { IState } from '@model/interfaces/state';

@Injectable({
    providedIn: 'root',
})
export class StateService extends StaticMetaItemService<IState> {
    constructor(public http: HttpClient) {
        super('StateService', 'State', 'StateIds', '/options/states', http);
    }
}
