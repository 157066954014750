import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';
import { IMetaItem } from '../interfaces/base';

import { IExpandableObject } from '../expandable-object';
import { ITask } from '../interfaces/task';
import { IAttachmentType } from '../interfaces/attachment-type';
import { IUser } from '../interfaces/user';
import { IDocument } from '../interfaces/document';
import { IDocumentQueueAttachment } from '../interfaces/document-queue-attachment';
import { IFollowupType } from '../interfaces/followup-type';
import { IImeAppointment } from '../interfaces/ime-appointment';
import { IImedClaimFollowup } from '../interfaces/imed-claim-followup';
import { IImedClaim } from '../interfaces/imed-claim';
import { IImedClaimServiceFollowup } from '../interfaces/imed-claim-service-followup';
import { IImedClaimService } from '../interfaces/imed-claim-service';
import { IServiceStatus } from '../interfaces/service-status';
import { IPhysician } from '../interfaces/physician';
import { IPhysicianStatus } from '../interfaces/physician-status';
import { ITaskStatus } from '../interfaces/task-status';
import { ITaskTemplate } from '../interfaces/task-template';
import { ITaskTemplateRecurrenceSchedule } from '../interfaces/task-template-recurrence-schedule';

export interface ITaskDynamicControlsParameters {
    formGroup?: string;
    imedClaims?: IImedClaim[];
    imedClaimServices?: IImedClaimService[];
    serviceStatuses?: IServiceStatus[];
    imedClaimServiceFollowups?: IImedClaimServiceFollowup[];
    imedClaimFollowups?: IImedClaimFollowup[];
    followupTypes?: IFollowupType[];
    attachmentTypes?: IAttachmentType[];
    imeAppointments?: IImeAppointment[];
    documents?: IDocument[];
    documentQueueAttachments?: IDocumentQueueAttachment[];
    taskStatuses?: ITaskStatus[];
    createdBies?: IUser[];
    modifiedBies?: IUser[];
    taskTemplateRecurrenceSchedules?: ITaskTemplateRecurrenceSchedule[];
    taskTemplateRecurrenceCounts?: ITaskTemplate[];
    taskTemplates?: ITaskTemplate[];
    masterFollowupTypes?: IFollowupType[];
    masterServiceStatuses?: IServiceStatus[];
    completedBies?: IUser[];
    physicians?: IPhysician[];
    physicianStatuses?: IPhysicianStatus[];
}

export class TaskDynamicControls {

    formGroup: string;
    imedClaims?: IImedClaim[];
    imedClaimServices?: IImedClaimService[];
    serviceStatuses?: IServiceStatus[];
    imedClaimServiceFollowups?: IImedClaimServiceFollowup[];
    imedClaimFollowups?: IImedClaimFollowup[];
    followupTypes?: IFollowupType[];
    attachmentTypes?: IAttachmentType[];
    imeAppointments?: IImeAppointment[];
    documents?: IDocument[];
    documentQueueAttachments?: IDocumentQueueAttachment[];
    taskStatuses?: ITaskStatus[];
    createdBies?: IUser[];
    modifiedBies?: IUser[];
    taskTemplateRecurrenceSchedules?: ITaskTemplateRecurrenceSchedule[];
    taskTemplateRecurrenceCounts?: ITaskTemplate[];
    taskTemplates?: ITaskTemplate[];
    masterFollowupTypes?: IFollowupType[];
    masterServiceStatuses?: IServiceStatus[];
    completedBies?: IUser[];
    physicians?: IPhysician[];
    physicianStatuses?: IPhysicianStatus[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private task?: ITask, additionalParameters?: ITaskDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'Task';
        this.imedClaims = additionalParameters && additionalParameters.imedClaims || undefined;
        this.imedClaimServices = additionalParameters && additionalParameters.imedClaimServices || undefined;
        this.serviceStatuses = additionalParameters && additionalParameters.serviceStatuses || undefined;
        this.imedClaimServiceFollowups = additionalParameters && additionalParameters.imedClaimServiceFollowups || undefined;
        this.imedClaimFollowups = additionalParameters && additionalParameters.imedClaimFollowups || undefined;
        this.followupTypes = additionalParameters && additionalParameters.followupTypes || undefined;
        this.attachmentTypes = additionalParameters && additionalParameters.attachmentTypes || undefined;
        this.imeAppointments = additionalParameters && additionalParameters.imeAppointments || undefined;
        this.documents = additionalParameters && additionalParameters.documents || undefined;
        this.documentQueueAttachments = additionalParameters && additionalParameters.documentQueueAttachments || undefined;
        this.taskStatuses = additionalParameters && additionalParameters.taskStatuses || undefined;
        this.createdBies = additionalParameters && additionalParameters.createdBies || undefined;
        this.modifiedBies = additionalParameters && additionalParameters.modifiedBies || undefined;
        this.taskTemplateRecurrenceSchedules = additionalParameters && additionalParameters.taskTemplateRecurrenceSchedules || undefined;
        this.taskTemplateRecurrenceCounts = additionalParameters && additionalParameters.taskTemplateRecurrenceCounts || undefined;
        this.taskTemplates = additionalParameters && additionalParameters.taskTemplates || undefined;
        this.masterFollowupTypes = additionalParameters && additionalParameters.masterFollowupTypes || undefined;
        this.masterServiceStatuses = additionalParameters && additionalParameters.masterServiceStatuses || undefined;
        this.completedBies = additionalParameters && additionalParameters.completedBies || undefined;
        this.physicians = additionalParameters && additionalParameters.physicians || undefined;
        this.physicianStatuses = additionalParameters && additionalParameters.physicianStatuses || undefined;

        this.Form = {
            Archived: new DynamicField({
                formGroup: this.formGroup,
                label: 'Archived',
                name: 'Archived',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.task && this.task.hasOwnProperty('Archived') && this.task.Archived != null ? this.task.Archived : false,
            }),
            AssignedToTypeId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Assigned To Type',
                name: 'AssignedToTypeId',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.task && this.task.AssignedToTypeId || null,
            }),
            AttachmentTypeId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Attachment Type',
                name: 'AttachmentTypeId',
                options: this.attachmentTypes,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.task && this.task.AttachmentTypeId || null,
            }),
            CompletedById: new DynamicField({
                formGroup: this.formGroup,
                label: 'Completed By',
                name: 'CompletedById',
                options: this.completedBies,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.task && this.task.CompletedById || null,
            }),
            CreatedById: new DynamicField({
                formGroup: this.formGroup,
                label: 'Created By',
                name: 'CreatedById',
                options: this.createdBies,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.task && this.task.CreatedById || 0,
            }),
            DateCompleted: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date Completed',
                name: 'DateCompleted',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.task && this.task.DateCompleted || null,
            }),
            DateCreated: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date Created',
                name: 'DateCreated',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.task && this.task.DateCreated || null,
            }),
            DateModified: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date Modified',
                name: 'DateModified',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.task && this.task.DateModified || null,
            }),
            DocumentId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Document',
                name: 'DocumentId',
                options: this.documents,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.task && this.task.DocumentId || null,
            }),
            DocumentQueueAttachmentId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Document Queue Attachment',
                name: 'DocumentQueueAttachmentId',
                options: this.documentQueueAttachments,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.task && this.task.DocumentQueueAttachmentId || null,
            }),
            DueDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Due Date',
                name: 'DueDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.task && this.task.DueDate || null,
            }),
            FollowupTypeId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Followup Type',
                name: 'FollowupTypeId',
                options: this.followupTypes,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.task && this.task.FollowupTypeId || null,
            }),
            ImeAppointmentId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Ime Appointment',
                name: 'ImeAppointmentId',
                options: this.imeAppointments,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.task && this.task.ImeAppointmentId || null,
            }),
            ImedClaimFollowupId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Imed Claim Followup',
                name: 'ImedClaimFollowupId',
                options: this.imedClaimFollowups,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.task && this.task.ImedClaimFollowupId || null,
            }),
            ImedClaimId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Imed Claim',
                name: 'ImedClaimId',
                options: this.imedClaims,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.task && this.task.ImedClaimId || null,
            }),
            ImedClaimServiceFollowupId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Imed Claim Service Followup',
                name: 'ImedClaimServiceFollowupId',
                options: this.imedClaimServiceFollowups,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.task && this.task.ImedClaimServiceFollowupId || null,
            }),
            ImedClaimServiceId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Imed Claim Service',
                name: 'ImedClaimServiceId',
                options: this.imedClaimServices,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.task && this.task.ImedClaimServiceId || null,
            }),
            IsAutomated: new DynamicField({
                formGroup: this.formGroup,
                label: 'Is Automated',
                name: 'IsAutomated',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.task && this.task.hasOwnProperty('IsAutomated') && this.task.IsAutomated != null ? this.task.IsAutomated : true,
            }),
            IsPhysicalCheckRequiredToFacility: new DynamicField({
                formGroup: this.formGroup,
                label: 'Is Physical Check Required To Facility',
                name: 'IsPhysicalCheckRequiredToFacility',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.task && this.task.hasOwnProperty('IsPhysicalCheckRequiredToFacility') && this.task.IsPhysicalCheckRequiredToFacility != null ? this.task.IsPhysicalCheckRequiredToFacility : false,
            }),
            MasterFollowupTypeId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Master Followup Type',
                name: 'MasterFollowupTypeId',
                options: this.masterFollowupTypes,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.task && this.task.MasterFollowupTypeId || null,
            }),
            MasterServiceStatusId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Master Service Status',
                name: 'MasterServiceStatusId',
                options: this.masterServiceStatuses,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.task && this.task.MasterServiceStatusId || null,
            }),
            ModifiedById: new DynamicField({
                formGroup: this.formGroup,
                label: 'Modified By',
                name: 'ModifiedById',
                options: this.modifiedBies,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.task && this.task.ModifiedById || null,
            }),
            Notes: new DynamicField({
                formGroup: this.formGroup,
                label: 'Notes',
                name: 'Notes',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(500) ],
                validators: { 'maxlength': 500 },
                value: this.task && this.task.hasOwnProperty('Notes') && this.task.Notes != null ? this.task.Notes.toString() : '',
            }),
            ParentTaskId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Parent Task',
                name: 'ParentTaskId',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.task && this.task.ParentTaskId || null,
            }),
            PhysicianId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Physician',
                name: 'PhysicianId',
                options: this.physicians,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.task && this.task.PhysicianId || null,
            }),
            PhysicianStatusId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Physician Status',
                name: 'PhysicianStatusId',
                options: this.physicianStatuses,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.task && this.task.PhysicianStatusId || null,
            }),
            ServiceStatusId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Service Status',
                name: 'ServiceStatusId',
                options: this.serviceStatuses,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.task && this.task.ServiceStatusId || null,
            }),
            TaskDescriptions: new DynamicField({
                formGroup: this.formGroup,
                label: 'Task Descriptions',
                name: 'TaskDescriptions',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Textarea,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.task && this.task.hasOwnProperty('TaskDescriptions') && this.task.TaskDescriptions != null ? this.task.TaskDescriptions.toString() : '',
            }),
            TaskStatusId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Task Status',
                name: 'TaskStatusId',
                options: this.taskStatuses,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.task && this.task.TaskStatusId || null,
            }),
            TaskTemplateId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Task Template',
                name: 'TaskTemplateId',
                options: this.taskTemplates,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.task && this.task.TaskTemplateId || null,
            }),
            TaskTemplateRecurrenceCount: new DynamicField({
                formGroup: this.formGroup,
                label: 'Task Template Recurrence Count',
                name: 'TaskTemplateRecurrenceCount',
                options: this.taskTemplateRecurrenceCounts,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.task && this.task.TaskTemplateRecurrenceCount || null,
            }),
            TaskTemplateRecurrenceScheduleId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Task Template Recurrence Schedule',
                name: 'TaskTemplateRecurrenceScheduleId',
                options: this.taskTemplateRecurrenceSchedules,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.task && this.task.TaskTemplateRecurrenceScheduleId || null,
            }),
            TriggeredFromServiceFacilityFeePhysicalCheckToFacilityRequired: new DynamicField({
                formGroup: this.formGroup,
                label: 'Triggered From Service Facility Fee Physical Check To Facility Required',
                name: 'TriggeredFromServiceFacilityFeePhysicalCheckToFacilityRequired',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.task && this.task.hasOwnProperty('TriggeredFromServiceFacilityFeePhysicalCheckToFacilityRequired') && this.task.TriggeredFromServiceFacilityFeePhysicalCheckToFacilityRequired != null ? this.task.TriggeredFromServiceFacilityFeePhysicalCheckToFacilityRequired : false,
            }),
            TriggeredFromServicePhysicalCheckRequiredToFacility: new DynamicField({
                formGroup: this.formGroup,
                label: 'Triggered From Service Physical Check Required To Facility',
                name: 'TriggeredFromServicePhysicalCheckRequiredToFacility',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.task && this.task.hasOwnProperty('TriggeredFromServicePhysicalCheckRequiredToFacility') && this.task.TriggeredFromServicePhysicalCheckRequiredToFacility != null ? this.task.TriggeredFromServicePhysicalCheckRequiredToFacility : false,
            }),
            TriggeredFromServiceProcessServerFee: new DynamicField({
                formGroup: this.formGroup,
                label: 'Triggered From Service Process Server Fee',
                name: 'TriggeredFromServiceProcessServerFee',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.task && this.task.hasOwnProperty('TriggeredFromServiceProcessServerFee') && this.task.TriggeredFromServiceProcessServerFee != null ? this.task.TriggeredFromServiceProcessServerFee : false,
            }),
            TriggeredFromServiceWitnessFeePhysicalCheckToFacilityRequired: new DynamicField({
                formGroup: this.formGroup,
                label: 'Triggered From Service Witness Fee Physical Check To Facility Required',
                name: 'TriggeredFromServiceWitnessFeePhysicalCheckToFacilityRequired',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.task && this.task.hasOwnProperty('TriggeredFromServiceWitnessFeePhysicalCheckToFacilityRequired') && this.task.TriggeredFromServiceWitnessFee != null ? this.task.TriggeredFromServiceWitnessFee : false,
            }),
            TriggeredFromServiceWitnessFeePhysicalCheckToForeignCourtRequired: new DynamicField({
                formGroup: this.formGroup,
                label: 'Triggered From Service Witness Fee Physical Check To Foreign Court Required',
                name: 'TriggeredFromServiceWitnessFeePhysicalCheckToForeignCourtRequired',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.task && this.task.hasOwnProperty('TriggeredFromServiceWitnessFeePhysicalCheckToForeignCourtRequired') && this.task.TriggeredFromServiceWitnessFeePhysicalCheckToForeignCourtRequired != null ? this.task.TriggeredFromServiceWitnessFeePhysicalCheckToForeignCourtRequired : false,
            }),
        };

        this.View = {
            Archived: new DynamicLabel({
                label: 'Archived',
                value: this.task && this.task.hasOwnProperty('Archived') && this.task.Archived != null ? this.task.Archived : false,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            AssignedToTypeId: new DynamicLabel({
                label: 'Assigned To Type',
                value: this.task && this.task.AssignedToTypeId || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            }),
            AttachmentTypeId: new DynamicLabel({
                label: 'Attachment Type',
                value: getMetaItemValue(this.attachmentTypes as unknown as IMetaItem[], this.task && this.task.hasOwnProperty('AttachmentTypeId') ? this.task.AttachmentTypeId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            CompletedById: new DynamicLabel({
                label: 'Completed By',
                value: getMetaItemValue(this.completedBies as unknown as IMetaItem[], this.task && this.task.hasOwnProperty('CompletedById') ? this.task.CompletedById : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            CreatedById: new DynamicLabel({
                label: 'Created By',
                value: getMetaItemValue(this.createdBies as unknown as IMetaItem[], this.task && this.task.hasOwnProperty('CreatedById') ? this.task.CreatedById : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            DateCompleted: new DynamicLabel({
                label: 'Date Completed',
                value: this.task && this.task.DateCompleted || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            }),
            DateCreated: new DynamicLabel({
                label: 'Date Created',
                value: this.task && this.task.DateCreated || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            }),
            DateModified: new DynamicLabel({
                label: 'Date Modified',
                value: this.task && this.task.DateModified || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            }),
            DocumentId: new DynamicLabel({
                label: 'Document',
                value: getMetaItemValue(this.documents as unknown as IMetaItem[], this.task && this.task.hasOwnProperty('DocumentId') ? this.task.DocumentId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            DocumentQueueAttachmentId: new DynamicLabel({
                label: 'Document Queue Attachment',
                value: getMetaItemValue(this.documentQueueAttachments as unknown as IMetaItem[], this.task && this.task.hasOwnProperty('DocumentQueueAttachmentId') ? this.task.DocumentQueueAttachmentId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            DueDate: new DynamicLabel({
                label: 'Due Date',
                value: this.task && this.task.DueDate || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            }),
            FollowupTypeId: new DynamicLabel({
                label: 'Followup Type',
                value: getMetaItemValue(this.followupTypes as unknown as IMetaItem[], this.task && this.task.hasOwnProperty('FollowupTypeId') ? this.task.FollowupTypeId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            ImeAppointmentId: new DynamicLabel({
                label: 'Ime Appointment',
                value: getMetaItemValue(this.imeAppointments as unknown as IMetaItem[], this.task && this.task.hasOwnProperty('ImeAppointmentId') ? this.task.ImeAppointmentId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            ImedClaimFollowupId: new DynamicLabel({
                label: 'Imed Claim Followup',
                value: getMetaItemValue(this.imedClaimFollowups as unknown as IMetaItem[], this.task && this.task.hasOwnProperty('ImedClaimFollowupId') ? this.task.ImedClaimFollowupId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            ImedClaimId: new DynamicLabel({
                label: 'Imed Claim',
                value: getMetaItemValue(this.imedClaims as unknown as IMetaItem[], this.task && this.task.hasOwnProperty('ImedClaimId') ? this.task.ImedClaimId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            ImedClaimServiceFollowupId: new DynamicLabel({
                label: 'Imed Claim Service Followup',
                value: getMetaItemValue(this.imedClaimServiceFollowups as unknown as IMetaItem[], this.task && this.task.hasOwnProperty('ImedClaimServiceFollowupId') ? this.task.ImedClaimServiceFollowupId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            ImedClaimServiceId: new DynamicLabel({
                label: 'Imed Claim Service',
                value: getMetaItemValue(this.imedClaimServices as unknown as IMetaItem[], this.task && this.task.hasOwnProperty('ImedClaimServiceId') ? this.task.ImedClaimServiceId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            IsAutomated: new DynamicLabel({
                label: 'Is Automated',
                value: this.task && this.task.hasOwnProperty('IsAutomated') && this.task.IsAutomated != null ? this.task.IsAutomated : true,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            IsPhysicalCheckRequiredToFacility: new DynamicLabel({
                label: 'Is Physical Check Required To Facility',
                value: this.task && this.task.hasOwnProperty('IsPhysicalCheckRequiredToFacility') && this.task.IsPhysicalCheckRequiredToFacility != null ? this.task.IsPhysicalCheckRequiredToFacility : false,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            MasterFollowupTypeId: new DynamicLabel({
                label: 'Master Followup Type',
                value: getMetaItemValue(this.masterFollowupTypes as unknown as IMetaItem[], this.task && this.task.hasOwnProperty('MasterFollowupTypeId') ? this.task.MasterFollowupTypeId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            MasterServiceStatusId: new DynamicLabel({
                label: 'Master Service Status',
                value: getMetaItemValue(this.masterServiceStatuses as unknown as IMetaItem[], this.task && this.task.hasOwnProperty('MasterServiceStatusId') ? this.task.MasterServiceStatusId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            ModifiedById: new DynamicLabel({
                label: 'Modified By',
                value: getMetaItemValue(this.modifiedBies as unknown as IMetaItem[], this.task && this.task.hasOwnProperty('ModifiedById') ? this.task.ModifiedById : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            Notes: new DynamicLabel({
                label: 'Notes',
                value: this.task && this.task.hasOwnProperty('Notes') && this.task.Notes != null ? this.task.Notes.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            ParentTaskId: new DynamicLabel({
                label: 'Parent Task',
                value: this.task && this.task.ParentTaskId || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            }),
            PhysicianId: new DynamicLabel({
                label: 'Physician',
                value: getMetaItemValue(this.physicians as unknown as IMetaItem[], this.task && this.task.hasOwnProperty('PhysicianId') ? this.task.PhysicianId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            PhysicianStatusId: new DynamicLabel({
                label: 'Physician Status',
                value: getMetaItemValue(this.physicianStatuses as unknown as IMetaItem[], this.task && this.task.hasOwnProperty('PhysicianStatusId') ? this.task.PhysicianStatusId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            ServiceStatusId: new DynamicLabel({
                label: 'Service Status',
                value: getMetaItemValue(this.serviceStatuses as unknown as IMetaItem[], this.task && this.task.hasOwnProperty('ServiceStatusId') ? this.task.ServiceStatusId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            TaskDescriptions: new DynamicLabel({
                label: 'Task Descriptions',
                value: this.task && this.task.hasOwnProperty('TaskDescriptions') && this.task.TaskDescriptions != null ? this.task.TaskDescriptions.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Textarea,
                    scale: null,
                }),
            }),
            TaskStatusId: new DynamicLabel({
                label: 'Task Status',
                value: getMetaItemValue(this.taskStatuses as unknown as IMetaItem[], this.task && this.task.hasOwnProperty('TaskStatusId') ? this.task.TaskStatusId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            TaskTemplateId: new DynamicLabel({
                label: 'Task Template',
                value: getMetaItemValue(this.taskTemplates as unknown as IMetaItem[], this.task && this.task.hasOwnProperty('TaskTemplateId') ? this.task.TaskTemplateId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            TaskTemplateRecurrenceCount: new DynamicLabel({
                label: 'Task Template Recurrence Count',
                value: getMetaItemValue(this.taskTemplateRecurrenceCounts as unknown as IMetaItem[], this.task && this.task.hasOwnProperty('TaskTemplateRecurrenceCount') ? this.task.TaskTemplateRecurrenceCount : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            TaskTemplateRecurrenceScheduleId: new DynamicLabel({
                label: 'Task Template Recurrence Schedule',
                value: getMetaItemValue(this.taskTemplateRecurrenceSchedules as unknown as IMetaItem[], this.task && this.task.hasOwnProperty('TaskTemplateRecurrenceScheduleId') ? this.task.TaskTemplateRecurrenceScheduleId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            TriggeredFromServiceFacilityFeePhysicalCheckToFacilityRequired: new DynamicLabel({
                label: 'Triggered From Service Facility Fee Physical Check To Facility Required',
                value: this.task && this.task.hasOwnProperty('TriggeredFromServiceFacilityFeePhysicalCheckToFacilityRequired') && this.task.TriggeredFromServiceFacilityFeePhysicalCheckToFacilityRequired != null ? this.task.TriggeredFromServiceFacilityFeePhysicalCheckToFacilityRequired : false,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            TriggeredFromServicePhysicalCheckRequiredToFacility: new DynamicLabel({
                label: 'Triggered From Service Physical Check Required To Facility',
                value: this.task && this.task.hasOwnProperty('TriggeredFromServicePhysicalCheckRequiredToFacility') && this.task.TriggeredFromServicePhysicalCheckRequiredToFacility != null ? this.task.TriggeredFromServicePhysicalCheckRequiredToFacility : false,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            TriggeredFromServiceProcessServerFee: new DynamicLabel({
                label: 'Triggered From Service Process Server Fee',
                value: this.task && this.task.hasOwnProperty('TriggeredFromServiceProcessServerFee') && this.task.TriggeredFromServiceProcessServerFee != null ? this.task.TriggeredFromServiceProcessServerFee : false,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            TriggeredFromServiceWitnessFeePhysicalCheckToFacilityRequired: new DynamicLabel({
                label: 'Triggered From Service Witness Fee Physical Check To Facility Required',
                value: this.task && this.task.hasOwnProperty('TriggeredFromServiceWitnessFeePhysicalCheckToFacilityRequired') && this.task.TriggeredFromServiceWitnessFee != null ? this.task.TriggeredFromServiceWitnessFee : false,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            TriggeredFromServiceWitnessFeePhysicalCheckToForeignCourtRequired: new DynamicLabel({
                label: 'Triggered From Service Witness Fee Physical Check To Foreign Court Required',
                value: this.task && this.task.hasOwnProperty('TriggeredFromServiceWitnessFeePhysicalCheckToForeignCourtRequired') && this.task.TriggeredFromServiceWitnessFeePhysicalCheckToForeignCourtRequired != null ? this.task.TriggeredFromServiceWitnessFeePhysicalCheckToForeignCourtRequired : false,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
        };

    }
}
