import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { IEntityListDynamicCellComponent, IEntityListComponentMembers } from '@mt-ng2/entity-list-module';
import { IEntity } from '@mt-ng2/base-service';
import { IModalOptions } from '@mt-ng2/modal-module';
import { ImedClaimService } from '../imed-claim.service';
import { IImedClaim } from '@model/interfaces/imed-claim';
import { forkJoin } from 'rxjs';
import { safeDetectChanges } from '../../common/cdr-safety/cdr-safety.library';
import { NotificationsService } from '@mt-ng2/notifications-module';

@Component({
    template: `
        <div *ngIf="this.requestImedClaimAccess" class="text-center">
            <button (mtConfirm)="requestAccess($event)" [mtConfirmOptions]="requestAccessConfirm" class="btn btn-success">Request Access</button>
        </div>
    `,
})
export class ImedClaimRequestAccessDynamicCellComponent implements IEntityListDynamicCellComponent, OnInit {
    entityListComponentMembers: IEntityListComponentMembers;
    set entity(value: IEntity) {
        this.imedClaim = value as IImedClaim;
    }

    imedClaim: IImedClaim;
    requestImedClaimAccess: boolean;

    requestAccessConfirm: IModalOptions = {
        confirmButtonText: 'Yes, I am sure!',
        text: `Are you sure you want to request access?`,
        title: 'Request Access',
        icon: 'question',
    };

    constructor(private clientPortalImedClaimService: ImedClaimService,
        private cdr: ChangeDetectorRef,
        private notificationsService: NotificationsService
    ) {}


    ngOnInit(): void {
        forkJoin([this.clientPortalImedClaimService.isIndividualDirectlyRelatedToCaseOrHasAccess(this.imedClaim.Id)]).subscribe(
            ([isIndividualDirectlyRelatedToCaseOrHasAccess]) => {
                this.requestImedClaimAccess = isIndividualDirectlyRelatedToCaseOrHasAccess === false;
                safeDetectChanges(this.cdr);
            },
        );
    }

    requestAccess(event: Event): void {
        this.clientPortalImedClaimService.requestAccess(this.imedClaim.Id).subscribe(
            () => {
                this.notificationsService.success('Access Requested');
            }
        );
    }
}
